import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/home-main.svg";
import Particle from "../Particle";
import About from "../About/About";
import Type from "./Type";

function Home() {
  return (
    <section style={{'margin-top':'100px'}}>
      <Container fluid className="about-section">
        {/*<Particle />*/}
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Hi There!{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
              </h1>

              <h1 className="heading-name">
                I'M
                <strong className="main-name"> Rajan Sethi</strong>
              </h1>

              <div style={{ 'padding-left': 50, textAlign: "left" }}>
                <Type />
              </div>
            </Col>

            {/*<Col md={5} style={{ paddingBottom: 20 }}>
              <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "450px" }}
              />
            </Col>*/}
          </Row>
        </Container>
      </Container>
     <About />
    </section>
  );
}

export default Home;
