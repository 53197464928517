import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Rajan Sethi </span>
            from <span className="purple"> India.</span>
            <br /> I am Data Scientist specialises in applied NLP task and GenAI.
            <br />
            I have worked on multiple projects for various clients or my previous employers. Projects like ChatBots, Text-Summarization, Conversational AI, Semantic Search.
            <br/>I have worked on various transformer models like BERT, DistilBERT, RoBerta, T5, llama2, flan2 and various vector data bases like chroma DB, FACE and so on.
            <br />
            <br />
            Apart from working, i love to do -
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Trading
            </li>
            <li className="about-activity">
              <ImPointRight /> Physical trainings
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
          </ul>

         {/* <p style={{ color: "rgb(155 126 172)" }}>
            "Strive to build things that make a difference!"{" "}
          </p>
          <footer className="blockquote-footer">Soumyajit</footer>*/}
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
